import React from 'react';

// import './JavaBibleScreen.css';

import { BsFillFolderSymlinkFill } from 'react-icons/bs';

import logoMaratonaJavaDevDojo from '../../resources/images/maratonaJavaDevDojoLogo.jpg';

import ImportantInformationsSummaryScreen from './ImportantInformationsSummaryScreen';
import NextBibleProjectsSummaryScreen from './NextBibleProjectsSummaryScreen';

export default function SummaryScreen() {

    // OBS: Esse método é copiado e colado em todas as Screens e atualiza o ancor da Navbar de acordo com qual Screen esta ativa

    // Método que adiciona estilo no ancor correspondente dessa screen na Navbar, pois esse é a screen em renderização corrente
    // Poderia utilizar estados globais como Context ou Redux, porém não quis fazer Over Engineering, e Frontend não é mais meu
    // contexto! (Atualmente estou mais focado em Backend com Java e Spring)
    const setStyleInCorrespondingAncorOfNavbarBecauseIsTheCurrentScreen = () => {
        const navbarElement = document.querySelector("#navbar-container");

        // Nas primeiras chamadas (Chamadas que ocorrem requisições no servidor) a navBar é null, 
        // porisso a lógica principal referente a essa funcionalidade esta na "components/mainNavbar/MainNavbar.js"
        // Esse bloco só é executado quando o react-router-dom é ativado, ou seja, quando ocorre mudanças de rotas
        // no client-side e já tem o DOM Virtual em cache, o react não faz requisições novamente, ele aproveita esse cache
        // porisso o useEffect na funcionalidade principal "components/mainNavbar/MainNavbar.js" não é executado!
        // Esse bloco foi feito para cenários em que o usuário utiliza as setas do navegador <= ou => para voltar ou
        // avançar no histórico de sites/URLs visitados.
        // ALÉM disso todo o roteamento do react-router-dom ocorre no lado do cliente (client-side), ou seja, 
        // Quando acessamos "/#/algumaCoisa" e essa rota esta mapeada no roteamento do react-router-dom, NÃO
        // ocorrem requisições para o servidor, TODA a lógica de renderizar e etc ocorre no cliente, isso é a magia
        // pois alivia a carga do servidor e mantém a navegação do usuário mais fluida! mas tem que conhecer esse
        // comportamento, pois a depender do escopo em que está definido a lógica ou vai rolar processamentos no
        // servidor ou no cliente!!! 
        if (navbarElement != null) { 

            // limpa os outros e deixa apenas estilos no ancor de acordo com a URL:
            for(let a of navbarElement.childNodes){ 
                a.classList.remove("-ancorOfNavbarIsClicked");
            }

            navbarElement.childNodes[1].classList.add("-ancorOfNavbarIsClicked"); // <- Alteração só ocorre aqui, nas outras Screens
            
        }
        
    }
    setStyleInCorrespondingAncorOfNavbarBecauseIsTheCurrentScreen();

    return (
    <section id="main-javaBible--container">
        <div>
            <h1 className="main-title">&lsaquo;Sumário - Resumo dos Módulos&rsaquo;</h1>
            <div className="wrapper-projects">
                <article className="projects-single">
                    <div className="projects-right">
                        <div className="projects-title--container">
                            <div>
                                <h3 className="projects-title">Maratona Java - DevDojo<br/><br/>EM DESENVOLVIMENTO!<br/>(Testando Layouts).</h3>
                                <a href="https://github.com/WelBert-dev/MaratonaJava-DevDojo/tree/main/src/main/java" target="_blank"><i><BsFillFolderSymlinkFill /></i></a>
                            </div>
                            <span>- Dicionário completo para consultas, desde fundamentos, conceitos e convenções até a API Nativa.</span>
                            <span>- 22 Mandamentos (Módulos) que descrevem a estrutura interna do Java ou da JVM, contendo todo o ciclo de vida do Software na perspectiva do Java.</span>
                            <span>- Transferindo informações do repositório git de estudos correspondente "MaratonaJava-DevDojo" no Github para este Site.</span>
                            <span>- Em desenvolvimento, então ainda possuem páginas vazias ou sem muitas informações (Paciência rsrs).</span>
                            <span>- Caso queira maiores informações ou módulos faltantes, ir no repositório do Projeto correspondente "WelBert-dev/MaratonaJava-DevDojo" no Github, ou clique no botão acima.</span>
                            <span>- Grande parte do conhecimento é devido ao curso "Maratona Java" do canal no YT "@DevDojoBrasil".</span>
                            <span>- Informações faltantes ou complementares das videoaulas adicionadas utilizando ChatGPT, e outras fontes ou canais de estudos do YT relacionados a desenvolvimento com Java.</span>
                            <span>- Carga de conhecimentos em POO, desenvolvimento e lógica de programação em geral acumulados ao longo de quase 3 anos de estudos intensos envolto da tecnologia.</span>
                            <span>- Sou entusiasta GNU/Linux então também fiz algumas alusões, referências, convenções ou comparações sobre como o S.O Ubuntu lida em determinados cenários, em conjunto ou em relação ao Java (Pois é nele que eu desenvolvo desde quando iniciei meus estudos em programação e desenvolvimento no começo de 2021).</span>
                            <span>- Desde o inicio eu quis simular ao máximo possível o ambiente real de produção, uma vez que a maioria dos servidores em cloud é alguma distro GNU/Linux em containers.</span>
                            <span>- TODOS meus projetos estão abertos aos curiosos e entusiastas de desenvolvimento e programação no Github (Inclusive o código fonte deste Site e do meu Portfólio).</span>
                            <p>Repositório Github clicando no botão acima</p>
                        </div>
                        <div>
                            <ul className="projects-description--container">
                                <li>
                                    <span className="-spanOfLinkAkitaDesbAlgTwitter">Playlist completa do curso no Youtube em: <a className="-linkBoldYellowProjects" href="https://www.youtube.com/watch?v=VKjFuX91G5Q&list=PL62G310vn6nFIsOCC0H-C2infYgwm8SWW" target="_blank">Clique AQUI!</a></span>
                                </li>
                            </ul>
                            <img className="projects-logo" src={logoMaratonaJavaDevDojo} alt="Amostra logo DevDojo Maratona Java" ></img>
                        </div>
                        <div className="subContainer-bottom">
                            <ul className="projects-description--container">

                                <ImportantInformationsSummaryScreen />

                                <NextBibleProjectsSummaryScreen />

                                <li>
                                    <h3 className="projects-subtitle">Sumário</h3>
                                    <ul className="projects-frontend--container">
                                        <li ><code className="-main-moduleTitleLi token_reservada">OverView geral sobre POO:</code> Menor nível de informações se comparado a outros módulos abaixo pois eu já estudei POO com C# no IFSP, logo, documentei apenas informações especificas de como o Java lida;</li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Errors, Exceptions, e RuntimeExceptions:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Tratamento de erros;</li>
                                                <li>Estrutura hierárquica (tudo no Java é Objeto, logo as Exeções também possuem herança, todos extends de Throwable);</li>
                                                <li>Diferenças entre Error e Exception;</li>
                                                <li>Tipos Checked para herdeiros de Exception e Unchecked para herdeiros de RuntimeException;</li>
                                                <li>Liberando recursos automáticamente de maneira implicita para classes que implements Closeable e/ou AutoCloseable com try-with-resourses;</li>
                                                <li>Sobrecarga de catchs para tratamentos partindo do mais Genérico para o mais Especializado;</li>
                                                <li>Múlti catch in line com pipe `|`;</li>    
                                                <li>Dentre outras informações mais completas na seção especifica dele...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Wrappers dos Tipos Primitivos:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Adapter que encapsula os valores dos Tipos Primitivos em forma de Objetos para contextos que esperam eles, alocando memória para a referência e o valor, alterando assim o comportamento de passagem por valor dos primitivos para passagem por referẽncia dos Objetos;</li>
                                                <li>Também explica mais sobre essa ação que é conhecida como "AutoBoxing/Boxing" e o processo inverso em que desencapsulamos esses Objetos retornando eles para variáveis primitivas comum é conhecido como "AutoUnboxing/Unboxing";</li>
                                                <li>É importante conhecer esses processos pois em alguns cenários de múltiplas iterações com somas e etc utilizando eles pode ser computacionalmente CUSTOSO devido a JVM ficar encapsulando e desencapsulando, alocando e re-alocando memória, além de outros processamentos necessários para a construção de objetos;</li>
                                                <li>Fornecem também funcionalidades prontas para cada tipo primitivo (Utilitários);</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Strings e Strings Constants Pool:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Aqui não é explicado "O que é uma String" e sim como o Java lida com elas na implementação em baixo nível;</li>
                                                <li>String Constants Pool (Implementação da técnica String Interning no Java) técnica implementada na JVM para otimizar recursos de armazenamento quando utilizado Strings Literais (Regra NÃO se aplica para Strings alocadas manualmente com `new`);</li>
                                                <li>Também explicamos pontos a se considerar devido a essa implementação que visa otimizar recursos de armazenamento porém em alguns cenários de múltiplas iterações de concatenação pode ser computacionalmente CUSTOSO;</li>
                                                <li>Então a solução é utilizar outras classes de Strings especializadas para lidar com esse problema (StringBuilder Não Thread-Safe e StringBuffer Thread-Safe);</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Manipulação de Data e Hora (Maneira Legada e Nova):</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Maneira Legada: </li>
                                                <li>Representação em baixo nível por um long que representa os milissegundos de 01/01/1970 com limitação até 17/08/292278994 04:12 (valor máximo de um long), essa representação com máscara de data e tempo é uma camada de alto nível adicionada aos Objetos temporais quando utilizamos classes de formatação, podendo assim representar os millisegundos 54548484158 computacionalmente entendível em Strings com máscaras de data e hora humanamente entendível;</li>
                                                <li>Principais classes legadas Temporais: Date e Calendar;</li>
                                                <li>Principais classes utilitárias legadas para formatação: DateFormat e SimpleDateFormat;</li>
                                                <li>Internacionalização de Datas com a classe Locale (Obs: Mais informações especificas sobre Internacionalização no Módulo especifico para isto);</li>
                                                <br/>
                                                <li>Maneira Nova: </li>
                                                <li>Devido a problemas de limitações da representação em baixo nível utilizando o long e a problemas de internacionalização, apartir do Java 8 é adicionado um pacote inteiro especializado em Data e Tempo java.time, com maior coesão entre as classes centralizando e especializando cada tipo de representação em uma classe especifica (Porisso de duas (2) classes principais Temporais, agora são quatro (4)), e também melhor lida com concorrência em ambientes multiThreads e paralelismo, criado por um Brasileiro e adotado pelo Java;</li>
                                                <li>Agora a representação em baixo nível ainda é em millisegundos, porém é suportando até "+999999999-12-31" e "-999999999-01-01";</li>
                                                <li>Principais classes novas Temporais: LocalDate, LocalTime, LocalDateTime, e Instant;</li>
                                                <li>Principal classe utilitária novas para formatação: DateTimeFormatter (Substitui a DateFormat quando utilizado classes Temporais de Data e Tempo);</li>
                                                <li>Principal classe (TemporalAdjusters) e interface (TemporalAdjuster) utilitária de ajuste no tempo, a interface é utilizada como parâmetro de vários métodos presentes nas classes Temporais ditas anteriormente, podemos então personalizar regras de negocios implementando essa interface, ou utilizar funcionalidades prontas na classe que serve como um Factory de objetos TemporalAdjuster, alguns métodos interessantes incluem (Obter o próximo dia útil da semana, Último dia do mês, Primeiro dia do mês, e etc...);</li>
                                                <li>Classes utilitárias para pegar intervalos entre duas Datas ou dois Tempos: Duration (apenas para datas e horas em conjunto na mesma classe (neste caso a LocalDateTime) ou APENAS horas cruas, SEM DATAS SIMPLES) e Period (apenas para datas simples, SEM HORAS (neste caso a LocalDate) chega para suprir o que a Duration não consegue);</li>
                                                <li>Classe ChronoUnit que é tipo uma tipagem para Dia, Mes, Ano, Horas, Minutos, Segundos, e etc (Podemos também realizar algums operações como pegar intervalos entre dois dias com ChronoUnit.DAY.beetween(talDia, talDia));</li>
                                                <li>Também abordamos outras classes relacionados a zona (diferenças entre fusos horários e etc) sendo elas: OffSet, ZonedId, ZonedDateTime, ZoneOffSet, OffSetDateTime;</li>
                                                <li>Em resumo uma representação completa em alto nível (String) seria composta por: Data/THora:Minuto:Segundo.Milissegundos/+OffSet/[Zona] (Exemplo: "2022-12-26T19:45:19.447985416+09:00[Asia/Tokyo]");</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele...</li>
                                            </ul>            
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Internacionalização:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Polimorfisando e adequando o mesmo sistema para diversos paises de acordo com a classe Locale que representa os paises e internacionaliza várias outras classes da API Nativa do Java em geral;</li>
                                                <li>Internacionalização de Datas com Locale: A representação da máscara de formatação em alto nível (String) é diferente entre os diversos paises, como por exemplo nos EUA a máscara de datas é no padrão "yyyy/mm/dd", diferente do Brasil que é no padrão "dd/mm/yyyy", então utilizamos a classe Locale para formatar a representação em alto nível, adequando assim o sistema para suportar diferentes paises utilizando praticamente a mesma implementação, alterando apenas a instância do Objeto Locale;</li>
                                                <li>Internacionalização de Números com Locale e NumberFormat.getInstance(Locale.JAPAN): A representação da máscara dos números também são diferentes entre os diversos paises, como por exemplo o ponto flutuante "." que é o separador de milhares no Brasil, porém nos EUA é a vírgula ",", então utilizamos a classe Locale para formatar essa representação, adequando assim o sistema para suportar diferentes paises utilizando praticamente a mesma implementação, alterando apenas a instância do Objeto Locale;</li>
                                                <li>Internacionalização de Moedas com Locale e NumberFormat.getCurrencyInstance(Locale.ITALY): A representação da máscara das Moedas também é diferentes entre os diversos paises, como por exemplo o mesmo separador de milhares ditos anteriormente, e também o simbolo das Moedas, para o Japão a representação é algo parecido com "￥10,000" (Observamos que o separador de milhares do Japão é vírgula "," ao invés de ponto flutuante ".", além disto lá também não existe valores fracionários, eles são redondos), que é totalmente diferente do Brasil "R$10,000.21", então utilizamos a classe Locale para formatar essa representação, adequando assim o sistema para suportar diferentes paises utilizando praticamente a mesma implementação, alterando apenas a instância do Objeto Locale; (Obs IMPORTANTE: A internacionalização das moedas utilizando esse processo aqui descrito NÃO faz conversões de câmbio (câmbio de moeda, ou também taxa de câmbio) entre elas, apenas adéqua a máscara (Separadores de milhares e etc ",", ".") e símbolos (R$, U$, ￥));</li>
                                                <li>Dicionário para traduções automáticas de acordo com Locale, dentre outras possibilidades utilizando ResourceBundle;</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Regex (Expressões Regulares) ou também Regexp (Regular Expression):</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>"Sublinguagem" utilizada para encontrar padrões em textos, os conceitos e representações dos caracteres especiais de configuração (meta caracteres) servem não apenas para o Java como é praticamente universal;</li>
                                                <li>Em particular no Java utilizamos duas (2) classes especializadas para este contexto sendo elas o Pattern que representa o padrão/máscara em search utilizando esses caracteres especiais de configuração e o Matcher para realizar a busca e comparação match em sí da expressão no texto;</li>
                                                <li>Um exemplo de utilidade para elas é identificar URL's "http://www.wubalubadubdub.rick.morty.com" aonde toda URL tem essa máscara "(www|http:|https:)+[^\s]+[\w]", essa expressão regex é a mais simples para URL's não sendo ideal para ambiente de produção;</li>
                                                
                                                <li>Meta caracteres (Caracteres de configuração sobre o padrão em busca); Existem vários; (Exemplo: `\d`, `\D`, `\w`, `\W`);</li>
                                                <li>Range (Intervalo) de valores com `[]`; (Exemplo: `[1-9]`, `[a-zA-Z]`);</li>
                                                <li>Quantificadores quando desejamos retornar vários valores do range `([])*`; Existem: `?`, `*`, `+`, `&#123;n-min, n-max&#125;`, `()`, `|`, `$`; (Exemplo: `([1-9])*`);</li>
                                                <li>Ancor `^` que possibilita duas funcionalidades distintas a depender do contexto aonde está inserido (`[^\w]` que indica que queremos EXATAMENTE a expressão do interválo, ou `^[\w]` utilizando por fora do range que funciona como negação `!`, ou seja, Tudo menos os valores retornados pela expressão do interválo);</li>
                                                <li>Também falamos um pouco sobre a classe Scanner, não é relacionado diretamente com Regex, porém o parâmetro delimitador que faz o split no texto e transforma em lista é uma expressão regular;</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele...</li>
                                            </ul>
                                        
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">I/O (Input/Output) Maneira Legada:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Como lidar com entrada e saída, por exemplo salvar arquivos em HD e depois recupera-los;</li>
                                                <li>Principal classe que representa o arquivo ou diretório em sí em forma de Objeto: File;</li>
                                                <li>Escrever em arquivos com a classe FileWriter (Obs: Precisamos de uma instância de File para criar ela, passando no construtor ele);</li>
                                                <li>Ler informações e recuperá-las em formato de Objeto com a classe FileReader (Obs: Também precisamos de uma instância de File para criar ela, passando no construtor ele);</li>
                                                <li>Devido as classes de leitura e escrita trabalharem em baixo nível de maneira não otimizada, temos as equivalentes BufferedWriter e BufferedReader que é mais performática, pois primeiro elas trabalham em memória RAM e só no final da operação é realizado a escritura ou leitura no HD (Obs: Mesmo assim ainda é necessário uma instância de FileReader ou FileWriter para essas criações também);</li>
                                                <li>Visualizar e modificar meta informações como data de criação, ultimas modificações ou também ir além como modificar ou ler permissões dos usuários e grupos;</li>    
                                                <li>Trabalhando com Diretórios (Mkdir): Segue as mesmas lógicas sobre criações e modificações de Arquivos, a única diferença é que o Path agora aponta para o parent;</li>    
                                                <li>Dentre outras informações mais completas na seção especifica dele (OBS: AINDA ESTÁ EM PROCESSO DE DESENVOLVIMENTO)...</li>
                                            </ul>
                                        
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">New I/O (Input/Output) Maneira Nova:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Nova maneira mais coesa de lidar com as mesmas operações anteriores, porém com maior coerência entre as classes, melhor performance e ajusta alguns erros que poderiam ocorrer se utilizar as legadas;</li>
                                                <li>Adiciona também a internacionalização de paths de acordo com diferentes tipos de Sistemas Operacionais pois cada um segue suas próprias convenções sobre as variáveis de ambiente, ou também utilizam diferentes tipos de sistemas de arquivos;</li>
                                                <li>Principal classe que representa o arquivo ou diretório é alterado o nome pois em relação a coesão, como a representação não é bem o Arquivo em sí que é mais genérico, aqui é representado como Path pois é basicamente isso que o File representava na maneira legada;</li>
                                                <li>Todos os métodos de instância da antiga classe File, sobre criar arquivos, copiar, modificar datas e etc agora é estático presente na classe utilitária Files;</li>
                                                <li>Classes que representam os meta dados dos arquivos são criados pelo utilitário Files e retornam implementações das Interfaces: BasicFileAttributes que é a mais genérica e basica de todos, NÃO podemos realizar modificações (write) com ela, apenas visualizar (read) esses meta dados; Para realizar modificações (write) nesses meta dados utilizamos as equivalentes com final "View", ou seja, para esta o equivalente seria o BasicFileAttributesView;</li>
                                                <li>Sobre o ponto anterior, também temos as equivalentes porém mais especializadas para os diferentes tipos de Sistemas Operacionais: DosFileAttributes para ambientes DOS (Windows-Like) e o equivalente para modificações dela DosFileAttributesView; PosixFileAttributes para ambientes POSIX (Unix-Like) e o equivalente para modificações dela PosixFileAttributesView;</li>
                                                <li>Operações de normalize dos paths: Método que substitui os caracteres curingas de um path relacionadas as variáveis de ambientes e convenções gerais sobre, deixando o path "limpo" completo, sem esses caracteres, esse processo é importante para manter compatibilidade com diferentes tipos de Sistemas Operacionais ou sistemas de arquivos. (Exemplo: "/home/welbert/Documentos/github/MaratonaJava-DevDojo/../../arquivo.txt" resolvido ele fica assim: "/home/welbert/Documentos/arquivo.txt");</li>
                                                <li>Operações de resolve dos paths: Ele toma um caminho relativo como entrada e o combina com o caminho atual para produzir um caminho absoluto. (Exemplo: Caminho atual ("/var/log") combinado com o Caminho relativo ("messages") temos que o caminho absoluto será ("/var/log/messages"));</li>
                                                <li>Operações de relativize dos paths: Usado para calcular o caminho relativo entre dois caminhos, ou seja, determina como poderiamos navegar entre um diretório para o outro. (Exemplo: Origem ("/home/irineu/inemEu") para o Destino ("/home/welbert/dev") resultado tomando como partida apartir da Origem ("../../welbert/dev"), resultado tomando como ponto de partida apartir do Destino ("../../irineu/inemEu"));</li>
                                                <li>Iterando e navegando sobre diretórios em primeiro nível da hierarquia com DirectoryStream;</li>
                                                <li>Iterando e navegando sobre diretórios em nível all da hierarquia de maneira recursiva com SimpleFileVisitor;</li>
                                                <li>Comparando padrões de correspondência nos diretórios com PathMatcher em conjunto com a classe FileSystem para permitir a busca de arquivos em um sistema de arquivos com base em um padrão de correspondência;</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele (OBS: AINDA ESTÁ EM PROCESSO DE DESENVOLVIMENTO)...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Serialization:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>A serialização é o processo de converter um objeto numa sequência de bytes, para que possa ser armazenado num arquivo, transmitido através de uma rede ou armazenado num banco de dados;</li>
                                                <li>Para serializar um Objeto ele deve implementar a interface `Serializable` ou também é válido para a `Externalizable`;</li>
                                                <li>Explica soluções para quando temos classes aninhadas a outras como atributos e essas mesmas não implementem essas interfaces, como lidar com isso;</li>
                                                <li>Ou indo além e como podemos serializar classes de API's terceiras fora de nosso controle e essas mesmas não implementem também? Solução é uso de classes Adapters que encapsulam essas classes e realiza lógicas para contornar esse problema;</li>
                                                <li>Explica os possíveis problemas que podem ocorrer se tentar deserializar Objetos que as suas classes sofreram mudanças estruturais ao longo do tempo, tornando o Objeto antigo serializado agora incompatível com a classe dele;</li>
                                                <li>Porisso temos o serialVersionUID que é um ID gerado automáticamente pelas IDE's e servem para manter um versionamento da classe, valor que se altera quando modificado a mesma, assim evita o problema descrito anteriormente sobre as possiveis incompatibilidades de Objetos serializados de classes que sofreu mudanças estruturais ao longo do tempo;</li>
                                                <li>Explica a token `transient` que é utilizada em atributos para indicar a JVM que esse campo não deve ser serializado, ou seja, não deve considerar o valor deste campo no processo, assim quando deserializado esse campo é carregado com o valor padrão definido na classe deste objeto em questão e assim podemos inicializar com outros valores mais tarde;</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele (OBS: AINDA ESTÁ EM PROCESSO DE DESENVOLVIMENTO)...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Collections (Estrutura de Dados):</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Maior parte dos estudos;</li>
                                                <li>Demonstra tabelas relacionadas ao cálculo de complexidade Big-O para cada classe da API de coleções, esses cálculos é relacionado as quantidades de iterações necessárias para alcansar diferentes objetivos como por exemplo quais as melhores estruturas de dados para inserção e remoção de elementos, quais as melhores para pegar valores get ou para verificar se contains algum elemento x;</li>
                                                <li>Explica a importância sobre a devida implementação dos métodos `.equals()` e `.hashCode()` presentes em todos Objetos (Pois são métodos definidos na raiz de todos o Object) que trabalham em conjunto e devem estar linearmente coesos entre sí, eles são necessários pois as listas e coleções utilizam esses métodos dos objetos para realizar comparações de igualdade e armazena-los corretamente. (Porém devem ser sobrescrito e modificado de acordo com as necessidades especificas de cada classe, necessidades essas que indicam quais campos devem ser considerados na comparação de igualdade `==` entre dois Objetos, ou seja, quais atributos desse objeto devem ser iguais para que os mesmos sejam considerados iguais? PORÉM devemos nos atentar sobre a escolha desses atributos, pois os mesmos NÃO devem ser dinâmicos, ou seja, esses campos não devem sofrer muitas mudanças no ciclo de vida da aplicação, pois se sofrerem mudanças o hash gerado seram diferentes durante esse ciclo de vida, logo, possiveis inconsistência vão ocorrer, por exemplo na hora de realizar buscas em coleções que utilizam o hash dos Objetos para indexar (melhorando assim a velocidade de busca por elementos) que é o caso do HashSet, ao tentar realizar buscas constantes por elementos durante o ciclo de vida da aplicação e os mesmos possuirem atributos dinâmicos, a comparação entre o mesmo Objeto vai mudar devido a isso);</li>
                                                <li>Programação orientada a interface devido todas as classes relacionadas as coleções (Estrutura de dados) implementarem interfaces genéricas feitas para contextos especificos (List, Set, SortedSet, NavigableSet, Map (Não é bem uma coleção, mais detalhes no módulo));</li>
                                                <li>Interfaces Comparable e Comparator utilizadas por métodos que precisam realizar comparações (&#60;, &#62;, &#60;=, &#62;=, ==) entre Objetos (Obs: Diferente de `.equals()`), elas são necessárias para definir a regra sobre como saber quando um Objeto é maios que outro e etc;</li>
                                                <li>Organizando listas em ASC ou DESC (Obs: Os objetos em ordenação devem implementar a interface Comparable que define a ordenação natural dos elementos, ou então devemos fornecer um Comparator caso esse Objeto não implemente a interface);</li>
                                                <li>Busca binária que é um método otimizado para buscas em listas ou coleções ordenadas (ASC ou DESC) no qual particiona ela no meio a cada iteração, mais informações sobre como isso é implementado no módulo;</li>
                                                <li>Convertendo List to Array e visi versa;</li>
                                                <li>Classe que faz verificações antes de modificar (remover e etc) elementos durante o processo de iteração evitando CurrentModificationException, além disto todas as coleções utilizam ele "por debaixo dos panos" quando iteramos em listas ou coleções em geral, sendo ele o Iterator;</li>
                                                <li>Interface List define um contrato mais básico de todas as coleções, sendo uma coleção de elementos ordenados (sorting) pois os métodos otimizados dela utilizam lógicas que performam bem em coleções ordenadas como por exemplo o algoritmo de Busca Binária (Binary Search), e também possibilita acesso por index;</li>
                                                <li>Interface Set define um contrato aonde todos elementos são únicos, ou seja, DISTINCT não permite duplicatas, ele utiliza o método `.equals()` para garantir isso, a depender da implementação se ela possuir Hash na nomeclatura isso quer dizer que o acesso aos elementos se da pelo hashcode deles (Gerados pelo método `.hashCode()`, além de que por utilizar o hashcode a maneira como a lista é organizada quando inserido os elementos, NÃO é pela ordem em que adicionamos eles e sim pelo hashCode, ou seja, eles não garantem a ordenação inicial de inserção dos elementos, a solução para isto é a classe LinkedHashSet que garante a posição inicial de inserção dos elementos);</li>
                                                <li>Interface Map define um dicionário Key-Value, ou seja, obtemos os valores apartir das chaves correspondentes deles, os mapas não são consideradas coleções, ou seja, Não podemos utilizar o Polimorfismo que espera no contexto classes que implementam a interface Collection, Também não é possível utilizar os métodos da classe utilitária Collections;</li>
                                                <li>Implementações de List: ArrayList, e LinkedList;</li>
                                                <li>Implementações de Set: HashSet, e LinkedHashSet;</li>
                                                <li>Implementações de SortingSet e NavigableSet: TreeSet;</li>
                                                <li>Obs sobre TreeSet: Essa coleção em árvore falha no contrato da interface Set pois o mesmo NÃO utiliza o método `.equals()` para evitar duplicatas na coleção (NOT DISTINCT);</li>
                                                <li>Implementações de Map: HashMap, e LinkedHashMap;</li>
                                                <li>Por fim falamos sobre Queue e PriorityQueue;</li>
                                                <br/>
                                                <li>Obs: NÃO estudamos sobre Estrutura de Dados aqui, ou seja, não é explicado como é implementado "por debaixo dos panos" essas coleções, eu fiz outro curso da Loiane Groner relacionado a isso e os estudos estão no diretório "/ZA_estuturaDados" neste mesmo projeto Maratona Java no repositório git, lá abordei as Estruturas de Dados: ArrayList (Implementação que utiliza Arrays nativos em baixo nível), LinkedList (Listas ligadas são implementações não indexadas pois os elementos de cada nó faz referência ao próximo elemento e ao elemento anterior (Como se fosse um trem aonde cada elemento é um vagão (nó) ligado em outro), portanto, para obtermos os elementos devemos navegar nela até encontra-lo (Não permite acesso aleatório por index como as coleções baseadas em arrays nativos), devido a isso ela possui maior performance para modificações como adições ou remoções de elementos, porém não performa bem em algoritmos de buscas), Queue (Filas possuem comportamento FIFO (First in first out), aonde o primeiro elemento a entrar é o primeiro a sair) e PriorityQueue (Filas com prioridades, ou seja, os elementos são organizados no momento de inserção de novos elementos de maneria a ir adequando e re-alocando os elementos deixando como primeiro os elementos de maior prioridade), Stack (Pilhas possuem comportamento LIFO (Last in first out), aonde o último elemento a entrar é o primeiro a sair (Ao contrário das Queue));</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele (OBS: AINDA ESTÁ EM PROCESSO DE DESENVOLVIMENTO)...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Generics:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Substitui contextos aonde se utilizava a Classe raíz de todas Object na Maneira Legada (Devido ao polimorfismo aonde tudo é Object), porém a maneira legada utilizando Object ocorriam vários erros por quebrar o principio de tipos únicos em coleções por exemplo, então surge Generics para corrigir esse e outros problemas apartir do Java 5;</li>
                                                <li>Porém deve-se atentar pois Generics serve apenas para tempo de desenvolvimento pois ao compilar ocorre um processo chamado Type Erasure aonde os &#60;T&#62; (Diamond Operator) são substituidos por Object, isso ocorre para manter compatibilidade com JRE anteriores a esta versão 5 do Java;</li>
                                                <li>Podemos utilizar a Reflexão para contornar essa implementação e Obter as informações genéricas &#60;T&#62; apagadas em tempo de compilação, não sendo muito recomendado pois o uso de reflexão fere alguns principios de encapsulamento e também é um processo computacionalmente custoso;</li>
                                                <li>Explica sintaxe para criação de classes genéricas e métodos genéricos;</li>
                                                <li>Obs: NÃO é necessário tornar a classe como um todo genérica com a assinatura a nível de classe &#60;T&#62; para quando precisa apenas dos métodos genéricos, basta utilizar a sintaxe correta na assinatura deste método em questão para torna-lo genérico. (Vai ser adicionado apenas mais um token nesta assinatura dele, mais explicações no módulo completo sobre Generics);</li>
                                                <li>Uso de Wildcards para permitir que uma classe ou método trabalhe com qualquer tipo, ou um subtipo específico (Existem dois tipos de wildcards: `?` (Unbonded, ou seja, Ilimitado) e `? extends Type` (bounded, ou seja, Limitado) aonde `extends` pode ser `extends` ou `super`) Exemplo: public void meuMetodo(List&#60;? extends Number&#62; lista) ou seja, apenas vai aceitar listas que são subclasses de Number (Integer, Long, Double...);</li>
                                                <li>Inferência de tipo adicionado no Java 7 para construtores e métodos genéricos, o que permite que o tipo de objeto retornado ou o tipo de argumentos de entrada sejam inferidos pelo compilador sem a necessidade de especificação manual, tornando mais fácil escrever código genérico sem precisar especificar os tipos explicitamente;</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele (OBS: AINDA ESTÁ EM PROCESSO DE DESENVOLVIMENTO)...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Nested Inner Class:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Classes internas aninhadas a outras, forte acoplamento entre elas, utilizar apenas quando esta Inner Class não possuir dependências de uso externas desta classe na qual ela esta aninhada/definida;</li>
                                                <li>Elas possuem acesso a QUALQUER membro definido na classe Pai dela (Até mesmo membros privados);</li>
                                                <li>Elas podem ser acessadas apenas por meio de uma instância da classe externa (pai);</li>
                                                <li>Se a classe interna for declarada estática, ela pode ser acessada diretamente, sem precisar de uma instância da classe externa (pai);</li>
                                                <li>Elas são sub divididas em quatro (4) tipos a depender do contexto aonde elas estão definidas neste aninhamento: </li>
                                                <li>Static nested class: É uma classe estática que é definida dentro de outra classe. Ela pode ser acessada sem a necessidade de criar uma instância da classe externa (pai);</li>
                                                <li>Inner class: É uma classe não estática que é definida dentro de outra classe. Ela tem acesso aos membros não estáticos da classe externa (pai) e pode ser instanciada apenas por meio de uma instância da classe externa (pai);</li>
                                                <li>Local class: É uma classe definida dentro de um método ou bloco de código. Ela tem acesso aos membros da classe externa (pai) e pode ser usada para implementar funcionalidades que são específicas para um método ou bloco;</li>
                                                <li>Anonymous class: É uma classe sem nome que é definida e instanciada em uma única etapa. Ela é usada para implementar uma classe que é usada apenas uma vez e não precisa de um nome ou tipo específico;</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele (OBS: AINDA ESTÁ EM PROCESSO DE DESENVOLVIMENTO)...</li>
                                            </ul>                                
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Introdução a Programação Funcional - Parametrizando Comportamentos:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Delegamos as responsabilidades sobre as regras de comparações ou outros comportamentos desejados em algum método para o chamador definir de acordo com as suas necessidades especificas, realizando assim uma inversão de controle pois agora quem define a regra sobre o comportamento esperado de um método é quem o utiliza, desta forma tornamos esse método mais genérico (Por exemplo `findByName()` que pode facilmente ser copiado e colado como `findByOutroAtributo()` percebemos que a única mudança nesta implementação será as comparações do `if` logo podemos extrair em um método mais genérico `find(Predicate predicate)` aonde Predicate é uma interface funcional que representa um predicado, sendo ele uma função que recebe um argumento e retorna um valor booleano, ou seja, é exatamente o que precisamos para implementar algoritmos de filtragens `x -&#62; x.getAge() &#62;= 18`, observamos que esse corpo da Lambda respeita a assinatura do método ÚNICO do predicado (Recebe apenas UM argumento `x` e retorna um valor booleano `x.getAge() &#62;= 18`);</li>
                                                <li>Além desta Interface Funcional também possuem outras com diferentes Target Types e Functional Descriptor (Mais explicações abaixo ou no módulo mais completo sobre Funções Lambdas, basicamente é a assinatura do método ÚNICO desta Interface Funcional, ou seja, isto se refere aos tipos de entradas e retorno deste método em questão, isso é importante pois devido a sintaxe dos corpos das Lambdas não precisar explicitar os tipos de dados que estamos trabalhando, então para que essa sintaxe seja possível, devemos respeitar a assinatura do método ÚNICO em questão definido nesta Interface Funcional (Ou também serve para outras classes ou interfaces comuns, desde que respeitado todas essas regras impostas) que é o parâmetro aonde estamos aplicando essa função Lambda, para que a JVM possa executar sem a necessidade de especificar manualmente esses tipos de entradas e retornos neste corpo da Lambda);</li>
                                                <li>E é graças a esses Target Types e Functional Descriptors ditos anteriormente, que podemos aplicar a sintaxe das Funções Lambdas em praticamente qualquer classe ou interface (Mesmo não sendo anotadas com @FunctionalInterface) desde que elas possuam métodos ÚNICOS definidos, e desde que respeitemos a assinatura deste método em questão sobre os tipos de entradas e retorno no corpo da Lambda, na hora da chamada deste método (Isso é o Target Type (Tipo alvo é o tipo de dado esperado em um contexto onde uma expressão lambda, uma referência a método ou uma classe anônima são usados.) e o Functional Descriptor (Que é um termo usado para descrever a assinatura de um método funcional, ou seja, a lista de argumentos e o tipo de retorno do método.)); Um exemplo famoso sobre aplicar funções Lambdas em contextos não funcionais porém que respeitam essas regras aqui impostas é a possibilidade de aplicar a sintaxe Lambda em métodos que recebem como argumento a interface Runnable, pois ela possui apenas UM único método definido `run()` logo o corpo da Lambda compatível (Se NÃO utilizado parâmetros de entrada) na chamada é o equivalente da Interface Funcional Consumer que define um contrato em que não existem parâmetros de entrada e também não existem retornos (Void), logo a sintaxe do corpo da Lambda compatível seria algo como `Executors.newScheduledThreadPool(1)<br/>.scheduleWithFixedDelay(<code className="token_reservada">Runnable() -&#62; &#123;...&#125;</code>, 1, 5, TimeUnit.SECONDS);`</li>
                                                <li>É importante conhecer o conceito dita anteriormente sobre Interfaces Funcionais, Target Types e Functional Descriptor pois graças a esses mecanismos conseguimos aplicar programação funcional no Java (Além do POO), pois inicialmente o Java não foi desenhado para esse paradigma, então essa funcionalidade do Java moderno é meio que uma gambiarra ou macete para contornar e conseguirmos utilizar o melhor dos dois mundos, pois utilizar programação funcional em conjunto com programação orientada a objetos trás bastante simplicidade e maior coesão, tornando algumas implementações mais legíveis;</li>
                                                <li>Em resumo, aqui neste módulo é explicado as vantagens de se utilizar Programação Funcional em conjunto de POO.</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Programação Funcional - Funções Lambdas:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Interfaces Funcionais são interfaces que possuem apenas UM método e anotados com @FunctionalInterface, porisso é possível utilizar proramação funcional no Java, pois como só existe UM único método é ele quem será encontrado e executado pela JVM, só devido a isso é possivel utilizar essa sintaxe, uma vez que se tivesse mais métodos neste contrato ou classe como a JVM saberia qual é o método correto correspondente a esse corpo da Lambda já que não explicitamos essa informação?</li>
                                                <li>O que é um "Target Type" e "Functional Descriptor", esses conceitos são relevantes pois eles nos possibilita utilizar Lambdas também com Interfaces ou Classes que NÃO são Interfaces Funcionais desde que as mesmas respeitem essas regras impostas nessas interfaces, ou seja, desde que esses contextos tenham definidos apenas UM método e na chamada o corpo da Lambda deve respeitar a assinatura deste método unico em questão (Tipo de entrada dos argumentos, e tipos de retornos);</li>
                                                <li>Existem vários tipos de Interfaces Funcionais já implementados para problemas comuns, a diferença entre elas é a assinatura do método ÚNICO, ou seja, O tipo de entrada dos argumentos (Quando existe) e o tipo de retorno esperado (Quando existe), as mais comuns incluem:</li>
                                                <li>Predicate: É uma interface que representa um predicado, uma função que recebe um argumento e retorna um valor booleano. Obs: Quando precisamos de mais argumentos de entrada utilizamos BiPredicate, TriPredicate (O java não fornece após o Bi, mas basta copiar o método e realizar as modificações necessárias paar aceitar mais argumentos) (Exemplo: o método `.filter()` dos fluxos Streams ou outras operações de filtragens);</li>
                                                <li>Consumer: É uma interface que representa um consumidor, ou seja, uma função que recebe um argumento e não retorna nada. É comumente usada para realizar operações em objetos. (Exemplo: o método `forEach()` de todas as coleções.);</li>
                                                <li>Function: É uma interface que representa uma função que recebe um argumento e retorna um resultado (Porisso "Function" pois é basicamente o que uma função normal faz). Pode ser usada para transformar objetos de um tipo em outro. (Exemplo: o método `.map()` dos fluxos Stream);</li>
                                                <li>Supplier: É uma interface que representa um fornecedor, ou seja, uma função que não recebe argumentos e retorna um resultado. É frequentemente usada para gerar valores preguiçosamente. (Exemplo: métodos que retornam objetos e os mesmos NÃO possuem parâmetros no construtor (Ou seja, o construtor padrão empty deve estar definido) `Collections.nCopies()`);</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele (OBS: AINDA ESTÁ EM PROCESSO DE DESENVOLVIMENTO)...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Programação Funcional - Method Reference:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Substitui alguns contextos Lambdas para maior coesão, com uma sintaxe mais limpa `::`. Alguns contextos pois essa sintaxe só funciona quando o método que iremos executar NÃO possuir parâmetros de entrada devido a sintaxe. Se o mesmo possuir esses parâmetros de entrada então devemos substituir por Lambdas mesmo;</li>
                                                <li>Essa sintaxe serve apenas para o alto nível (A nível de código) e coesão pois "por debaixo dos panos" essa referência vai surtir o mesmo resultado em relação a processamentos, pois vai gerar as mesmas instruções que seriam geradas se utilizado funções Lambdas;</li>
                                                <li>Existem quatro (4) tipos de referências a métodos para cada tipo de cenário, sendo eles:</li>
                                                <li>Referência a métodos estáticos: `MyClass::staticMethod;`;</li>
                                                <li>Referência a métodos de instância em um objeto particular: `obj::instanceMethod;`;</li>
                                                <li>Referência a método de instância de um tipo de objeto arbitrário: `String::length;` Obs: Esse parece meio confuso, mas é simples, ele é utilizado quando vamos executar esse referência em objetos que ainda não existem porisso "arbitrário", mas irão ser criados em algum momento futuro;</li>
                                                <li>Referência a um construtor: `ArrayList::new;`;</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele (OBS: AINDA ESTÁ EM PROCESSO DE DESENVOLVIMENTO)...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Classe Optional:</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Introduzido no Java 8, utilizado para evitar/tratar NullPointerExceptions, minimizando retornos `null`, "", `0`, `false`;</li>
                                                <li>Bastante utilizado com fluxos Streams;</li>
                                                <li>Por se tratar de um Adapter que encapsula objetos que podem ser inexistentes não podemos utilizar esse tipo como parâmetro de entrada de métodos, nem tipo de atributos de classe, ou algo do tipo, apenas podemos utilizá-los como tipo de retorno;</li>
                                                <li>Possuem métodos úteis que podem realizar operações se o objeto existir ou outros que podem criar esse objeto caso ele não exista, ou também lançar exceções;</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele (OBS: AINDA ESTÁ EM PROCESSO DE DESENVOLVIMENTO)...</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li><code className="-main-moduleTitleLi token_reservada">Programação Funcional - Streams (Fluxo de dados):</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Introduzido no Java 8, utilizado para iterar e processar coleções de objetos como listas, conjuntos, mapas, arrays, e etc... com maior performance e de maneira mais concisa, eles possibilitam essas operações SEM a necessidade de ALTERAR a coleção original, com métodos prontos, e maior coesão tornando o código mais limpo;</li>
                                                <li>Iteração e processamento de coleções: A API de Streams permite percorrer e processar coleções, como listas, conjuntos, mapas e arrays, de forma mais concisa e eficiente;</li>  
                                                <li>Operações de filtragem: É possível filtrar os elementos de uma coleção com base em critérios específicos usando o método `filter(Predicate predicate)`. Isso permite selecionar apenas os elementos que atendem a determinadas condições;</li>
                                                <li>Transformações e mapeamentos: As operações de mapeamento permitem transformar os elementos de uma coleção em outros objetos. Os métodos `map(Function mapper)` e `flatMap(Function mapper)` são usados para realizar essas transformações;</li>
                                                <li>Agrupamento e agregação: A API de Streams oferece suporte a operações de agrupamento e agregação semelhantes às disponíveis em SQL. É possível realizar operações como agrupamentos, cálculos de média, máximo, mínimo, contagem e soma usando os métodos apropriados;</li>
                                                <li>Ordenação: A API de Streams permite ordenar os elementos de uma coleção com base em critérios específicos usando o método `sorted()`. É possível fornecer um comparador personalizado para controlar a ordem de classificação;</li>  
                                                <li>Limitação e paginação: É possível limitar o número de elementos retornados de um fluxo usando o método `limit(long maxSize)`. Isso pode ser útil para implementar recursos de paginação em uma coleção;</li>
                                                <li>Operações de busca e correspondência: A API de Streams oferece métodos para encontrar elementos em um fluxo, como `findFirst()`, `findAny()`, `anyMatch()`, `allMatch()` e `noneMatch()`. Esses métodos permitem verificar se os elementos atendem a certos critérios ou encontrar o primeiro ou qualquer elemento que corresponda a um predicado;</li>
                                                <li>Encadeamento de operações: As operações de Streams podem ser encadeadas para formar uma sequência de transformações e operações em uma coleção. Isso permite criar pipelines de operações que são executadas em sequência.</li>
                                                <li>Dentre outras informações mais completas na seção especifica dele...</li>
                                            </ul>    
                                        </li>
                                        <li><code className="-main-moduleTitleLi token_reservada">Threads:</code>
                                            <ul className="-nestedInnerUl">
                                                <li>Aqui é abordado a maneira mais baixo nível de lidar criando e configurando MANUALMENTE todo o processo, porisso "baixo nível", em alguns casos não é necessário realizar toda essa configuração manual pois existem classes mais modernas que adicionam uma camada a mais de abstração tornando esse processo mais facil, como por exemplo a classe ExecutorService ou ScheduledExecutorService (Para agendamentos de tarefas que devem ser executadas de tempos em tempos), que além de facilitar na criação das threads e limitação na quantidade de threads que seram criadas no `pool de threads`, também facilita nos tratamentos de concorrência por recursos entre elas.</li>
                                                <li>Como criar por meio de herança extends na classe Thread (Não muito recomendado) ou implementando implements a Interface Runnable (Mais indicado).</li>
                                                <li>Estado das threads (state of threads) `New` para quando criamos mas não iniciamos ainda com o método `.start()`, `Runnable` para quando damos `.start()` nelas indicando que pretendemos essa execução (Não executa ainda pois quem decide isso é o Scheduler do Sistema Operacional), `Blocked` para quando uma thread aguarda o Lock de um recurso e ela espera para adquirir o monitor do objeto, ou seja, isso geralmente ocorre quando esse recurso já está sendo acessado por outra thread e a mesma está segurando o Lock, `Waiting` para quando uma thread está aguardando indefinidamente por notificações de outras para assim poder continuar sua execução, `Time Waiting` ou  também `Waiting Blocked` para quando uma thread está aguardando porém com um tempo limite definido (Como por exemplo quando uma thread aguarda resposta de rede, ela não fica esperando infinitamente, ela aguarda dar time out, se não iria ocorrer um `DeadLock` mais explicações no módulo X), e finalmente `Terminated` para quando uma tarefa é concluída ou interrompida por algum motivo. OBS: A Thread pode executar e parar, e depois voltar a executar apartir do ponto em que ela parou, várias vezes, quem decide quando e qual thread será executada é o Scheduler.</li>
                                                <li>Podemos apenas definir níveis de prioridade maiores para algumas, porém mesmo assim quem decide é o Scheduler do Sistema Operacional.</li>
                                                <li>Existem dois (2) tipos de Threads sendo elas as de usuário `User` que são criadas por nós desenvolvedores e as do sistema `Daemon` que são criadas pela JVM (Ou também podemos criar esse tipo definindo `.setDaemon(true)`, esse tipo é útil para execuções de limpesas ou liberação de recursos em geral, tarefas nas quais não exigem prioridade e as mesmas podem ser finalizadas a qualquer momento) como por exemplo o garbage collector que sai varrendo e limpando a memória, a principal diferença entre elas duas (Daemon e User) é que o programa só finaliza quando termina a execução das threads de usuário User, e quando isso ocorre o programa é finalizado mesmo se ainda existir threads do tipo `Daemon` em execução, além disto as Threads do tipo `User` também tem maior prioridade de execução na hora de ser escalonada pelo Scheduler do Sistema Operacional.</li>
                                                <li>Métodos que alteram o fluxo de execução das threads, como se unir a outras (Aguardar que outra thread finalize sua tarefa definida no método sobrescrito `.run()` com `objOutraThread.join()` nela, e após essa finalização ela (thread corrente que executou `objOutraThread.join()`) continua a executar apartir dali, Ou também parar (dar dicas que quer parar) a execução da thread corrente tornando ela `Runnable` para quando a thread corrente já finalizou sua tarefa e pode deixar outras serem executadas, com o método estático `Thread.yield()`.</li>
                                            </ul>
                                        </li>
                                        <li><code className="-main-moduleTitleLi token_reservada">Threads - Synchronization (MultiThreads):</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Aqui é abordado a maneira mais baixo nível de lidar (Não tão baixo nível pois aqui não precisamos trabalhar diretamente com os Objetos implementados da interface Lock como a classe ReentrantLock, essas classes são utilizadas para implementar MANUALMENTE as regras de bloqueios de recursos, aumenta a complexidade porém possibilita maiores controles sobre sincronismo, abordado no módulo abaixo), bloqueando e sincronizando os Objetos "na unha".</li>
                                                <li>Sincronismo em threads é o processo no qual definimos regras sobre quando e quem pode acessar algum bloco de código, tornando o mesmo sincronizado com a token `synchronized` assim apenas uma thread por vez pode executar esse bloco de maneira "atômica", ou seja, a thread pega o Lock do Objeto recurso e executa esse bloco por completo sem que outra possa entrar e realizar mudanças nas variáveis por exemplo, atrapalhando umas as outras.</li>
                                                <li>Esse processo é a solução a nível de implementação necessário para tratar a concorrência por um recurso entre as threads, bloqueando um recurso para outras threads quando ele está sendo executado por outra, o token `synchronized` implementa isso tornando as threads de fora do bloco `waiting` até que esse recurso seja executado por completo pela thread que entrou no bloco e está segurando o Lock. DeadLock.</li>
                                                <li>Também abordamos o problema que pode ocorrer se utilizado a sincronização com `synchronized` de maneira errada, aonde um recurso é bloqueado por uma thread (otherThread) e essa mesma thread (otherThread) solicite algum outro recurso que também está bloqueado e esta sendo executado por outra thread (currentThread), e essa outra thread (currentThread) está solicitando neste bloco dela o recurso que está bloqueado pela outra thread (otherThread) que está esperando ela (currentThread), ou seja, bloqueio cruzado em X, aonde elas aguardam enternamente pelo recurso que a outra está segurando o Lock.</li>
                                                <li>Tomar cuidados ao utilizar o sincronismo com o `synchronized` em blocos inteiros, ou em várias partes que não precisariam serem sincronizados pois sincronizar blocos inteiros é praticamente tornar a execução do mesmo assincrona, ou seja, perder todas as vatagens do paralelismo, uma vez que esse bloco será executado da maneira comum lógicamente falando (Ou seja, vai surtir o mesmo efeito que se não o tivera), a única diferença é que ao invés da thread `main` executa-lo, vão ser diferentes threads, porém ainda sim da mesma maneira assincrona, em resumo, se esse bloco inteiro deve ser sincronizado, então não deveriamos estar aplicando paralelismo nele.</li>
                                                <li>Ou seja, devemos sincronizar apenas pontos especificos do código, como verificações de if's, atribuições em variáveis, ou qualquer outra funcionalidade que corre riscos de serem alterados por alguma thread enquanto a outra tenta acessar ou utilizar esse mesmo recurso. O que são classes Thread-Safe, são classes que possuem seus métodos sincronizados.</li>
                                                <li>Porém devemos nos atentar aos escopos no qual essas classes Thread-Safes estão sendo utilizadas, pois se esse escopo não for sincronizado também, não vai adiantar.</li>
                                                <li>Comunicação entre threads, também abordamos os métodos presentes em todas as classes pois eles são herdados extends de Object, os métodos `.wait()`, `.notify()` e `.notifyAll()` que são ferramentas para sincronização de threads e permitem que as threads cooperem entre si para executar tarefas de forma sincronizada e sem interferência, deixando elas em espera até que outras notifiquem indicando que elas podem continuar a executar.</li>
                                                <li>Um exemplo de utilidade famoso é a abordagem produto-consumidor aonde uma thread produtora cria objetos e notificam outras que são responsáveis por processar esses mesmos objetos (Em exemplo implementamos um sistema em que uma thread (produtora) é responsável por adicionar emails em uma pilha Stack lidos do usuário por uma janela gráfica, e quando ela adiciona ela faz a notificação para todas as threads (consumidoras) que são responsáveis por acessar essa mesma pilha e enviar esses emails, o sistema fica em looping, as threads responsáveis por enviar os emails ficam em estado de espera, até que o usuário digite um novo email, no qual será interceptado pela thread produtora que também está em estado de espera, após ler e adicionar na stack, a thread produtora notifica todas as threads consumidoras que estão em espera).</li>
                                                <li>Conceito de monitor de objetos e Objetos burros Lock.</li>
                                            </ul>   
                                        </li>
                                        <li><code className="-main-moduleTitleLi token_reservada">Threads - Concurrency (MultiThreads):</code> 
                                            <ul className="-nestedInnerUl">
                                                <li>Aqui é abordado as formas de lidar com a concorrência das threads ao acessar o mesmo recurso (Thread-Safe) utilizando classes do pacote com esse mesmo namespace (java.util.concurrent), classes essas que já lidam internamente com a concorrência realizando as operações de sincronização ditas nos módulos anteriores, adicionando assim mais uma camada de abstração para facilitar a vida dos desenvolvedores.</li>
                                                <li>Abordamos as classes atômicas (AtomicInteger, AtomicLong, AtomicBoolean, AtomicReference) que possuem métodos atômicos, o conceito de "atômico" é por conta da maneira em que os blocos dos métodos delas são executados, eles executam atomicamente, isto é, de uma só vez, geralmente esses métodos são duas ou mais operações em uma, como por exemplo `.getAndIncrement()` das classes AtomicInteger e AtomicLong, ou seja, o equivalente ao `return i++` porém executado como se fosse as duas funcionalidades de uma só vez, deixando de correr o risco de outra thread modificar esse recurso (enquanto a outra thread utiliza ele) antes da outra finalizar sua tarefa.</li>
                                                <li>Configurando e Sincronizando manualmente blocos de código com a classe ReentrantLock (Uma das implementações da Interface Lock), definindo regras personalizadas para o bloqueio e desbloqueio desses escopos/recursos, maneira mais complexa do equivalente primitivo `synchronized`.</li>
                                                <li>Classe Condition que fornece métodos aos equivalentes (em alternativa para) `.wait()`, `.notify()` e `.notifyAll()` do primitivo `synchronized`, porém para os Locks configurados manualmente com ReentrentLock (Ou outras implementações da Interface Lock), sendo eles `objCondition.await()`, `objCondition.signal()` e `objCondition.signalAll()`.</li>
                                                <li>Abordamos também a implementação da Interface Lock especializado ReentrantReadWriteLock em Leituras simultâneas de threads porém bloqueio apenas para escritas, ou seja, enquanto um recurso não for acessado para escritas ele permanece liberado para TODAS as threads que forem realizar a leitura, bloqueando para TODOS apenas quando alguma thread acessa esse recurso no modo escrita, garantindo assim a consistência dos dados.</li>
                                                <li>Também abordamos algumas classes de coleções Thread-Safe como a CopyOnWriteArrayList que não utiliza sincronismo na implementação, porém ainda sim é seguro para usos em ambientes multiThreads devido a forma como é implementada em baixo nível, utilizando um array comum porém para cada inserção e/ou remoção é re-alocado um novo array e é mantido a coleção original (porisso é Thread-Safe), ou seja, não é muito performática se for realizar muitas inserções ou remoções, só é util para muitas leituras e poucas gravações.</li>
                                                <li>Também abordamos a classe ArrayBlockingQueue que é uma das implementação da Interface BlockingQueue, responsáveis por definir filas que suportam operações de bloqueio e desbloqueio, isso é possível de acordo com a capacidade inicial definida ao criar essa fila (Ou seja, devemos saber qual será o tamanho dela na hora da criação), pois de acordo com essa initial capacity a thread que está bloqueada (em espera waiting) identifica se pode acessar o recurso ou não, pois se a capacidade não foi atingida então é possivel adicionar mais elementos a essa fila, logo, a thread fica em espera até que libere espaço para ela entrar e adicionar mais elementos por exemplo.</li>
                                                <li>Também abordamos a classe LinkedTransferQueue que é uma das implementação da Interface TransferQueue (E reune métodos de 3 outras classes em uma sendo elas as classes ConcurrentLinkedQueue, SynchronousQueue e LinkedBlockingQueue) que é uma extensão da Interface BlockingQueue, que adiciona um método adicional chamado `transfer()` que permite que uma thread coloque um elemento na fila e bloqueie até que outro thread retire esse elemento da fila (O método `transfer()` é diferente do método `put()` da interface BlockingQueue, pois ele bloqueia a thread produtora até que o elemento seja retirado da fila por outra thread consumidora, enquanto o método `put()` bloqueia a thread produtora apenas quando a fila está cheia), Também explicamos as diferenças entre os métodos que realizam as mesmas operações de inserir e remover elementos porém com diferentes formas de bloqueio e desbloqueios, isso pois como são as junções das 3 classes ditas no inicio, vários métodos são aparentemente iguais porém com diferenças em relação ao sincronismo e bloqueio.</li>
                                                <li>Também abordamos as classes que abstraem as criações e gerenciamento de Threads sendo ele os Executors e ExecutorService facilitando essas operações, as threads gerenciadas por eles vão para o pool de threads, também podemos definir limites na quantidade de threads a serem criadas para executar alguma tarefa. Também abordamos a Interface ScheduledExecutorService que é uma extensão da Interface ExecutorService, especializada para agendamentos de tarefas que devem ser executadas em momentos especificos ou com intervalos regulares de tempos (De tempo em tempo), por se tratar de um escalonador de tarefas então possibilita a execução de tarefas em segundo plano enquanto continua a realizar outras tarefas.</li>
                                                <li>Também abordamos sobre a Interface Callable que é o equivalente da Interface Runnable, poŕem com retorno no final da sua execução, diferente da Runnable que o retorno é Void. Devido a essa funcionalidade do Callable sobre retornar valores no final da tarefa, a currentThread ficará em espera await até que a tarefa definida no Callable seja finalizada e retorne o resultado para ela dar continuidade (Obs: Sempre é bom definir um time out de espera para operações deste tipo, pois a currentThread ficará em espera await infinitamente até que o recurso esteja disponível, possibilitando DeadLocks).</li>
                                                <li>Devido ao ponto anterior, utilizamos a classe Future que é a promessa de um valor que será retornado em algum momento futuro, em operações com uso do Callable.</li>
                                                <li>Também abordamos o uso do CompletableFuture que é o equivalente do Future porém em contextos em que não queremos utilizar o pool de threads dos Executors em baixo nível e sim o ForkJoinPool que é mais performático em alguns casos devido a natureza de particionar/dividir para então conquistar.</li>
                                                <li>ForkJoinPool é uma funcionalidade introduzida no java 7, a principal diferença entre ela e o pool de threads comum é a forma como ela é implementada e resolve alguns problemas especificos relacionados quando uma operação em paralelo possuí dependências com passos anteriores, isto é, o maior problema quando se tenta paralelisar tarefas em que as operações futuras dependem de processamentos anteriores a este passo, como por exemplo o cálculo de Fibonacci, ele é um exemplo classo sobre esse problema quando se tenta paralelisar esses tipos de tarefas que dependem de processamentos anteriores ao passo corrente, pois para calcular o novo número é necessário (dependência) os dois números anteriores deste passo na iteração, ou seja, se desejamos paralelisar essa tarefa como resolver isso? não é possível várias threads executarem essa tarefa em paralelo pois elas precisam do processamento umas das outras.</li>
                                                <li>O ForkJoinPool é uma irmã da ThreadPoolExecutor porém é utilizada em funções recursivas que possuem flags indicativas sobre o final da iteração (Só assim é possível ela resolver o problema de Fibonacci anterior) pois é preciso saber esse valor limite para poder dividir corretamente a tarefa entre as threads e ao final de cada processamento fragmentado os resultados são re-arranjados no final (estilo map-reduce), porisso "Particionar para então conquistar", o objetivo é conseguir dividir um problema em tarefas menores e executá-las independentemente, sem uso de sincronização (synchronized).</li>
                                            </ul>    
                                        </li>
                                        <li><code className="-main-moduleTitleLi token_reservada">Design Patterns (Gang Of Four GoF):</code> Soluções prontas e reutilizáveis para problemas comuns na POO, propostas pelos 4 amigos (Erich Gamma, Richard Helm, Ralph Johnson e John Vlissides) livro bem reconhecido pela comunidade Dev. (Obs: Não é abordado TODOS os Padrões pois são muitos 23 no Total).</li>
                                        <li><code className="-main-moduleTitleLi token_reservada">JDBC:</code> Plugando o Java com banco de dados, aonde o Java fornecendo Interfaces que as ORM's Implementam e faz essa conexão ocorrer, refletindo automáticamente as mudaças da aplicação no banco e devido a utilizar programação orientada a interface podemos facilmente alternar entre diversos banco de dados diferentes (MySQL, MariaDB, Oracle DB,  Microsoft SQL Server, PostgreSQL...)</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </section>
    )
};